<template>
	<div
		class="fixed filter flex bg-black50 bg-white rounded-xl absolute top-full text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
		:class="{ 'left-0': position === 'left', 'right-0': position === 'right' }"
		v-if="filterOpen === idFilter && item.filterType"
	>
		<form class="flex m-auto flex-col items-start w-72 py-5 bg-white rounded-xl shadow-lg popup-filter" onsubmit="return false;">
			<h3 class="flex px-5 text-gray-400 font-bold uppercase justify-between items-center w-full">
				<p>Сортировка</p>
				<span class="cursor-pointer" @click="$emit('change', null)">X</span>
				<!-- <span class="cursor-pointer" @click="switchFilterVisibility()" @click="$emit('switchFilterVisibility', null)">X</span> -->
			</h3>

			<label class="mt-4 px-5 cursor-pointer font-normal flex">
				<p>{{ item.label1 }}</p>
				<input class="custom-input relative" type="radio" name="sortBy" @change="sortFilter(idFilter, false, item.fieldName)" v-model="item.sortBy" :value="2" />
			</label>
			<label class="mt-4 px-5 cursor-pointer font-normal flex">
				<p>{{ item.label2 }}</p>
				<input class="custom-input relative" type="radio" name="sortBy" @change="sortFilter(idFilter, false, item.fieldName)" v-model="item.sortBy" :value="1" />
			</label>
			<h3 class="flex items-center w-full mt-5 pt-5 px-5 border-t border-gray-400 text-gray-400 font-bold uppercase">
				Фильтр
				<button
					type="reset"
					class="ml-auto text-xs font-normal text-blue-500 underline normal-case cursor-pointer"
					@click="item.value1 || (item.values && item.values.length > 0) || item.sortBy ? sortFilter(idFilter, true, item.fieldName) : ''"
				>
					Сбросить фильтр
				</button>
			</h3>

			<div v-if="item.filterType === 9">
				<div class="filter-select w-72 pt-3 px-5">
					<multiselect
						tag-placeholder=""
						placeholder=""
						:show-labels="false"
						v-model="item.selectValues"
						:options="item.filterOptions"
						:searchable="false"
						:multiple="true"
						label="name"
						track-by="name"
						:close-on-select="false"
						@select="setOptionState($event, item, true)"
						@remove="setOptionState($event, item, false)"
					>
						<template slot="option" slot-scope="props">
							<input class="mr-1.5" type="checkbox" v-model="props.option.checked" @focus.prevent />
							<span>{{ props.option.name }}</span>
						</template>
					</multiselect>
				</div>
			</div>
			<span v-else-if="item.filterValueType === 3">
				<label class="mt-4 px-5 cursor-pointer font-normal flex">
					<p>да</p>
					<input class="custom-input relative" type="radio" name="filterValue" @change="search(item, params.filters)" v-model="item.value1" :value="true.toString()" />
				</label>
				<label class="mt-4 px-5 cursor-pointer font-normal flex">
					<p>нет</p>
					<input class="custom-input relative" type="radio" name="filterValue" @change="search(item, params.filters)" v-model="item.value1" :value="false.toString()" />
				</label>
			</span>
			<span v-else-if="item.filterType === 1 && item.filterValueType === 1">
				<label class="mt-4 px-5 cursor-pointer font-normal flex">
					<p>да</p>
					<input class="custom-input relative" type="radio" name="filterValue" @change="search(item, params.filters)" v-model="item.value1" value="Да" />
				</label>
				<label class="mt-4 px-5 cursor-pointer font-normal flex">
					<p>нет</p>
					<input class="custom-input relative" type="radio" name="filterValue" @change="search(item, params.filters)" v-model="item.value1" value="Нет" />
				</label>
			</span>
			<span v-else-if="item.type && item.type === 'radiobutton'">
				<div class="mx-4 my-2">
					<label
						class="flex flex-col py-1 px-2 border-1 border-gray-500 text-white cursor-pointer rounded-sm"
						:class="{ 'bg-textGray': item.value1 !== '1', 'bg-blue-500': item.value1 == '1' }"
					>
						<span class="text-xs font-semibold uppercase">Пользовательский</span>
						<input class="custom-input2 hidden" type="radio" name="filterValue" @change="search(item, params.filters)" v-model="item.value1" value="1" />
					</label>
				</div>
				<div class="mx-4 mt-2">
					<label
						class="flex flex-col py-1 px-2 border-1 border-gray-500 text-white cursor-pointer rounded-sm"
						:class="{ 'bg-textGray': item.value1 !== '2', 'bg-blue-500': item.value1 === '2' }"
					>
						<span class="text-xs font-semibold uppercase">Экспертный</span>
						<input class="custom-input2 hidden" type="radio" name="filterValue" @change="search(item, params.filters)" v-model="item.value1" value="2" />
					</label>
				</div>
			</span>
			<span v-else-if="item.autocomplete">
				<Autocomplete
					:list="item.list"
					:item-key="item.itemKey"
					:widthStroke="item.widthStroke"
					:heightUl="item.heightUl"
					:timeout="item.timeout"
					:placeholder="item.placeholder"
					v-model="item.value1"
					@query="search(item, params.filters)"
				/>
			</span>
			<span v-else-if="item.filterType !== 3 && item.filterType !== 10">
				<input
					:type="item.filterValueType === 2 ? 'number' : 'text'"
					v-model="item.value1"
					v-filter="item.paramsFiter"
					@input="search(item, params.filters)"
					:placeholder="item.filterValueType === 2 ? 'Введите число...' : 'Введите текст...'"
					class="flex flex-grow mt-10 ml-3 pl-2 w-64 outline-none border-b border-gray-400"
				/>
			</span>
			<span v-else>
				<div class="relative flex flex-grow mt-3 mx-2 w-64 outline-none border-gray-400">
					<div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
						<svg class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
							<path
								fill-rule="evenodd"
								d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
								clip-rule="evenodd"
							></path>
						</svg>
					</div>
					<input
						type="date"
						min="01-01-2018"
						v-model="item.value1"
						onkeydown="return false"
						:max="currentDate"
						class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
						@change="search(item, params.filters)"
					/>
				</div>
			</span>
		</form>
	</div>
</template>

<script>
import debounce from 'lodash.debounce';
import Multiselect from 'vue-multiselect';
import Autocomplete from './Autocomplete.vue';

export default {
	name: 'FilterComponent',
	components: {
		Multiselect,
		Autocomplete
	},
	data() {
		return {
			currentDate: null,
			params: {
				filters: [],
				sortOption: {
					fieldName: '',
					sortType: null
				},
				pageSize: 100,
				currentPage: 0
			}
		};
	},
	props: {
		position: {
			type: String,
			default: 'right'
		},
		filterOpen: [Number, String],
		idFilter: [Number, String],
		item: Object,
		fields: Array,
		param: Object,
		defaultSortField: {
			type: String,
			default: ''
		},
		defaultSortOrder: {
			type: Number,
			default: 2
		},
		guidVal: {
			type: String,
			default: null
		}
	},
	methods: {
		getData(params, flag) {
			for (let i = 0; i < params.filters.length; i += 1) {
				if (params.filters[i].fieldName === 'sitelastupdate') {
					if (params.filters[i].value1 && params.filters[i].value2) {
						let splitVal1 = params.filters[i].value1.split('-'),
							splitVal2 = params.filters[i].value2.split('-');

						params.filters[i].value1 = `${splitVal1[2]}-${splitVal1[1]}-${splitVal1[0]}`;
						params.filters[i].value2 = `${splitVal2[2]}-${splitVal2[1]}-${splitVal2[0]}`;
					}
				}
			}

			this.params.currentPage = 0;
			this.$emit('query', params, flag, this.guidVal);
			this.$emit('fromPage', 0);
		},
		switchFilterVisibility(key) {
			this.$emit('changeFilter', key);
		},
		sortFilter(key, flag, fieldName) {
			let flagType = typeof this.idFilter === 'string';

			for (let i = 0; i < this.fields.length; i += 1) {
				if (flag) {
					if (key === (flagType ? `AC${i}` : i)) {
						if (this.fields[i].filterType === 9) {
							this.fields[i].filterOptions.forEach((x) => (x.checked = false));
							this.fields[i].selectValues = [];
							this.fields[i].values = [];
						} else {
							this.fields[i].value1 = '';
						}

						this.fields[i].sortBy = null;

						if (this.params.sortOption.fieldName === fieldName) {
							this.resetSort();
						}

						const index = this.params.filters.findIndex((f) => f.fieldName === fieldName);
						if (index >= 0) this.params.filters.splice(index, 1);

						break;
					}
				}
				if (key === (flagType ? `AC${i}` : i)) {
					this.params.sortOption.sortType = this.fields[i].sortBy;
				}
			}

			if (!flag) this.params.sortOption.fieldName = fieldName;

			this.getData(this.params, true, this.guidVal);

			this.$emit('change', null);
		},
		resetSort() {
			let sortField = this.fields.find((x) => x.fieldName === this.defaultSortField);

			if (sortField !== undefined) {
				sortField.sortBy = this.defaultSortOrder;
				this.params.sortOption.sortType = sortField.sortBy;
				this.params.sortOption.fieldName = sortField.fieldName;
			} else {
				this.params.sortOption.sortType = null;
				this.params.sortOption.fieldName = '';
			}
		},
		search: debounce(function (item, array) {
			// если дата
			if (item.filterType === 3 || item.filterType === 10) {
				let dateStart = new Date(item.value1),
					it = item;
				it.value2 = new Date(dateStart.setDate(dateStart.getDate() + 1)).toISOString().substr(0, 10);
			} else if (item.filterType === 9) {
				item.values = item.fieldName === 'YearStr' ? item.selectValues.map((x) => x.name) : item.selectValues.map((x) => x.id);
			}

			let obj = array.find((f) => f.fieldName === item.fieldName);

			if (array.length === 0 || !obj) {
				array.push(item);
			} else if (obj) {
				if (item.filterType === 9) {
					obj.values = item.values;
				} else {
					obj.value1 = item.value1;
				}
			}

			for (let i = 0; i < array.length; i += 1) {
				if (array[i].filterType === 9) {
					if (!array[i].values || array[i].values.length === 0) {
						array.splice(i, 1);
					}
				} else {
					if (!array[i].value1) {
						array.splice(i, 1);
					}
				}
			}

			let correctStructureFilter = this.params.filters.map((m) => ({
				fieldName: m.fieldName,
				filterType: m.filterType,
				filterValueType: m.filterValueType,
				value1: m.value1 ? m.value1 : undefined,
				value2: m.value2 ? m.value2 : '',
				values: m.values ? m.values : undefined
			}));

			// console.log(correctStructureFilter, this.params);

			this.getData(
				{
					columns: this.params.columns,
					currentPage: this.params.currentPage,
					filters: correctStructureFilter,
					noteid: this.params.noteid,
					pageSize: this.params.pageSize,
					sortOption: this.params.sortOption,
					isRandom600: this.params.isRandom600
				},
				true,
				this.guidVal
			);

			this.$emit('change', null);
		}, 1000),
		setOptionState(option, item, state) {
			let index = item.filterOptions.findIndex((x) => x.name === option.name);
			item.filterOptions[index].checked = state;

			this.search(item, this.params.filters);
		}
	},
	mounted() {
		this.params = this.param;
		this.currentDate = this.$moment().format('YYYY-MM-DD');
	}
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.filter-select > .multiselect {
	margin-bottom: 0;
}

.filter-select .multiselect__tags {
	font-weight: 400;
	font-size: 14px;
	line-height: 50px;
	min-height: 50px;
	max-width: 100% !important;
	width: 100%;
	border-radius: 10px;
	border: 1px solid #a8b1ce;
	padding: 0 40px 0 20px;
	color: #6a6e83;
	background: initial;
	align-items: center;
	overflow: hidden;
}

.filter-select .multiselect__tags-wrap {
	align-items: center;
	height: 50px;
	overflow: hidden;
}

.filter-select .multiselect__tag {
	display: inline;
	padding: 0;
	color: #6a6e83;
	margin-right: 0;
	background: transparent !important;
	white-space: normal;
}

.filter-select .multiselect__tag-icon {
	display: none;
}

.filter-select .multiselect__tag:not(:first-child)::before {
	content: ',';
	margin: 0 5px 0 -5px;
}

.filter-select .multiselect__option {
	white-space: normal;
}

.filter-select .multiselect__option--highlight:after {
	display: none;
}

.filter-select .multiselect__select {
	height: 50px;
}
</style>
<style scoped>
.filter {
	z-index: 999;
}
.filter input[type='number'] {
	-moz-appearance: textfield;
}
.custom-input {
	margin-left: 10px;
}
.custom-input:checked::after {
	content: '';
	position: absolute;
	display: block;
	width: 17px;
	height: 17px;
	top: -2px;
	left: -2px;
	/* background: url([object Object]) no-repeat; */
	z-index: 40;
}

.custom-input2:checked + label {
	background-color: rgba(59, 130, 246);
}
</style>
